import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Welcome, Login, TakePic, FaceRec, ThankYou } from "./pages";
import { Base } from "./container";
import { AnimatePresence } from "framer-motion";
import { IsUserRedirect, ProtectedRoute } from "./helpers/routes";
import * as ROUTES from "./constants/routes";
import KVKKPage from "./pages/KVKKPage";

export default function App() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    return (
        <Router>
            <AnimatePresence exitBeforeEnter>
                <Switch>
                    <Route path={ROUTES.FACEREC} render={() => <FaceRec />} />
                    <Route path={ROUTES.THANKYOU} render={() => <ThankYou name={name} password={password} />} />
                    <Route
                        path="*"
                        render={({ location }) => (
                            <Switch location={location} key={location.pathname}>
                                <Route
                                    exact
                                    path={ROUTES.LOGIN}
                                    render={() => (
                                        <Login
                                            name={name}
                                            setName={setName}
                                            password={password}
                                            setPassword={setPassword}
                                        />
                                    )}
                                />
                                <Route exact path={ROUTES.KVKK} render={() => <KVKKPage />} />
                                <Route exact path={ROUTES.TAKEPIC} render={() => <TakePic />} />

                                <Route path="*" render={() => <Welcome />} />
                            </Switch>
                        )}
                    />
                </Switch>
            </AnimatePresence>
        </Router>
    );
}
