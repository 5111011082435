import styled from "styled-components/macro";

export const Container = styled.div`
	display: flex;
	justify-content: center;
`;

export const Image = styled.img`
	display: flex;
	padding-top: 10px;
	width: 30%;

	@media (max-width: 1024px) {
		padding-top: 10px;
		width: 80%;
	}
	@media (max-width: 414px) {
		padding-top: 20px;
		width: 90%;
	}
`;
