import React, { useState } from "react";
import { Kvkk, EtkModal } from "../components";
import { Base } from "../container";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../constants/routes";

export default function KVKKPage() {
    const [kvkkStatus, setKvkkStatus] = useState(false);
    const [etkStatus, setEtkStatus] = useState(false);
    const [etkOpen, setEtkOpen] = useState(false);
    const history = useHistory();

    const handleEtkClick = () => {
        setEtkOpen(true);
    };

    const handleClick = () => {
        history.push(ROUTES.TAKEPIC);
    };
    const handleKvkkChange = (e) => {
        console.log(e.target.value);
        setKvkkStatus(e.target.value === "accept");
    };

    const handleEtkChange = (e) => {
        console.log(e.target.value);
        setEtkStatus(e.target.value === "etkAccept");
    };

    return (
        <Base>
            <Kvkk>
                <Kvkk.SubContainer>
                    <Kvkk.TextArea>
                        DOKTORLAR İÇİN AÇIK RIZA METNİ Santa Farma İlaç Sanayi A.Ş. (“Şirket veya Santa Farma”)
                        bünyesinde bulunan şahsıma ait her türlü kişisel verinin 6698 sayılı Kişisel Verilerin Korunması
                        Kanunu ve sair mevzuat kapsamında öngörülen düzenlemelere tabi olduğunu ve bu hususta Şirket’in
                        tarafıma ilgili kanun ve sair mevzuat kapsamındaki haklarımla ilgili olarak tam ve açık bir
                        bilgilendirme yaptığını kabul ve beyan ederim. İlgili bilgilendirme kapsamında, Şirket mevzuat
                        kapsamında veri güvenliğine ilişkin yükümlülüklerini tam ve eksiksiz olarak yerine getireceğini
                        taahhüt eder, buna istinaden kişisel verilerimin kanuna aykırı olarak işlenmesi ve ele
                        geçirilmesi sebebiyle zarara uğramam hâlinde zararın giderilmesini talep etme hakkımı saklı
                        tutarım. Bu kapsamda, Şirket tarafından görevlendirilmiş tanıtım mümessilleri dahil tüm Şirket
                        çalışanları ile yüz yüze veya telefon yoluyla iletişim;internet sitesi veya görüntülü/ sesli
                        iletişim sağlayan çevrimiçi/çevrimdışı platformlar aracılığıyla otomatik olarak veya üçüncü
                        kişiler vasıtası ile elde edilen şahsıma ait;&#013;&#010; • Ad soyad, doğum tarihi, uyruk,
                        kimlik ve kimlik numarası, anne-baba adı, pasaport numarası ve kimlik fotoğrafı;&#013;&#010; •
                        İşyeri adresi, ev adresi, e-posta, telefon ve cep telefonu bilgileri; • Akademik unvan, diploma
                        tescil no uzmanlık alanı, sicil no, çalışma planı, ünite ve branş bilgileri;&#013;&#010; • Vize
                        başvurusu yapmak ve değer aktarım formu doldurmak için işlenecek olan verileri ile • Dernek ve
                        vakıf üyeliği bilgilerini • Görsel ve işitsel verileri İçeren kişisel verilerimin; • Tanıtım ve
                        tanıtım amaçlı olmayan bilimsel yönde bilgilendirme metotlarının tarafıma iletilmesi, • Sağlık
                        meslek mensubu listelerinin oluşturulması, • Ürün, hizmet, tanıtım süreçleri ile ilgili olarak,
                        pazar araştırma uygulamalarının dahil olduğu, tanıtım faaliyetlerinin planlanması ve icrası, •
                        Ziyaret verilerinin tutulması, • Şirket çalışan performanslarının ölçülmesinde istatistiki veri
                        oluşturulması, • Yan etki bildirimlerinin alınması, • Yurt içi ve yurt dışı kongrelerin/
                        toplantıların/organizasyonların/eğitimlerin/sempozyumların planlanması ve yapılması, • Dijital
                        platformlarımızın oluşturulması, desteklenmesi ve iyileştirilmesi amacıyla ve dijital
                        platformlara üyelik oluşturulması amacıyla; Şirket tarafından https://www.santafarma.com.tr web
                        sayfasında yayınlanan Kişisel Verilerin Korunması ve İşlenmesi Politikalarına ve Aydınlatma
                        Metnine uygun olarak işlenmesine ve yurtiçindeki ilgili kamu kurum ve kuruluşlarına, sözleşmenin
                        mahiyeti gereği üçüncü kişi ve kuruluşlara, hizmet sağlayıcı firmalara ve Santa Farma
                        bünyesindeki yurtiçi ofislerine aktarılmasına rıza veriyorum. Versiyon: v1.0 Tarih: 2020-07-01
                    </Kvkk.TextArea>

                    <Kvkk.ConfirmContainer>
                        <form className="kvkk-confirm-form" onChange={handleKvkkChange}>
                            <p>Açık rıza metnini okudum.</p>
                            <Kvkk.RadioInput type="radio" name="kvkk-radio" value="accept" />
                            <Kvkk.Label htmlFor="accept">Onaylıyorum</Kvkk.Label>
                            <Kvkk.RadioInput type="radio" name="kvkk-radio" value="decline" />
                            <Kvkk.Label htmlFor="decline">Onaylamıyorum</Kvkk.Label>
                        </form>
                        <br />
                        <form className="etk-confirm-form" onChange={handleEtkChange}>
                            <span
                                style={{
                                    color: "rgb(0, 167, 224)",
                                    textShadow: "0px 0px 1px rgb(0, 167, 224), 0px 0px 1px rgb(0, 167, 224",
                                }}
                                onClick={handleEtkClick}
                            >
                                ETK izin metnini&nbsp;
                            </span>
                            okudum.
                            <Kvkk.RadioInput type="radio" name="etk-radio" value="etkAccept" />
                            <Kvkk.Label htmlFor="etkAccept">Onaylıyorum</Kvkk.Label>
                            <Kvkk.RadioInput type="radio" name="etk-radio" value="etkDecline" />
                            <Kvkk.Label htmlFor="etkDecline">Onaylamıyorum</Kvkk.Label>
                        </form>
                    </Kvkk.ConfirmContainer>
                    <Kvkk.Button isDisabled={!kvkkStatus || !etkStatus} onClick={handleClick}>
                        Devam etmek için tıklayınız
                    </Kvkk.Button>
                </Kvkk.SubContainer>
            </Kvkk>
            <EtkModal open={etkOpen} setOpen={setEtkOpen} />
        </Base>
    );
}
