import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "./style.css";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function EtkModal({ open, setOpen }) {
    const classes = useStyles();

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <textarea className="etk-text" readOnly spellCheck="false">
                        Santa Farma İlaç San. A.Ş. (“Şirket”),
                        https://santafarma.com.tr adresli internet sitesi
                        üzerinden ve tarafınıza Şirket tarafından tanımlanan
                        hizmetler kapsamında, ziyaretçiler ve kullanıcılardan
                        müşteri ve iletişim bilgileri toplamaktadır. Bu metne
                        onay vermeniz halinde 6563 Sayılı Elektronik Ticaretin
                        Düzenlenmesi Hakkında Kanun ve 15 Temmuz 2015 tarihli
                        29417 sayılı Resmi Gazete’de yayınlanan Ticari İletişim
                        ve Ticari Elektronik İletiler Hakkında Yönetmelik
                        kapsamında Şirket tarafından veya Şirket’in iş ortakları
                        tarafından, sunulan ürünler ve hizmetlere ilişkin,
                        telefon, elektronik posta, kısa mesaj hizmetlerinden
                        onay verdikleriniz kullanılarak elektronik ortamda
                        gerçekleştirilen ve ticari amaçlarla gönderilen veri,
                        ses ve görüntü içerikli her türlü ticari elektronik
                        ileti ile yapılacak tüm tanıtım, kampanya ve
                        bilgilendirme mesajı gönderimini kabul etmiş olursunuz.
                        Bu metne onay vermeniz dahilinde bu onayınız, reddetme
                        hakkınızı kullanıncaya kadar geçerli olacaktır. Metne
                        onay vermemeniz Şirket tarafından sağlanan hizmetleri
                        etkilemeyecektir. Size, iletişime geçilen her türlü
                        elektronik iletişim kanalı içerisinde, onayı geri çekme
                        yöntemine de yer verilecektir. Bu yöntemi kullanarak
                        tarafınızla ticari amaçla iletişime geçilmesini her
                        zaman durdurabilirsiniz. Yukarıdaki açıklamalar
                        ışığında, aşağıda imzası bulunan ben, ilgili müşteri
                        bilgilerimin, Şirket’in tarafıma yönelik yapacağı
                        tanıtım, özel teklif ve kampanyaların pazarlanması için
                        gerekli iletişim faaliyetleri ile anket uygulamaları da
                        dâhil olmak üzere her türlü ticari elektronik iletinin
                        gönderiminde kullanılmasını ve bu kapsamda ticari
                        elektronik ileti gönderiminin, Telefon, SMS/MMS/Cep
                        Telefonu ve E-posta yolu ile yapılmasını onaylıyorum.
                    </textarea>
                </Fade>
            </Modal>
        </>
    );
}
