import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width};
    font-family: "Teko", sans-serif;
    text-align: center;

    min-height: 50px;
    border: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 70px;
    color: rgb(0, 167, 224);
    background-color: #eee;
    padding: 4vh 3vw 3vh;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.6);

    transition: 0.3s;

    &:focus {
        outline: none;
    }
    &:hover {
        background-color: white;
        color: rgb(30, 197, 254);
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.3);
    }
    &:active {
        background-color: #ddd;
        box-shadow: none;
        color: rgb(2, 133, 204);
    }

    @media (max-width: 1024px) {
        width: 70%;
        font-size: 90px;
    }

    @media (max-width: 768px) {
        width: 70%;
        font-size: 70px;
    }

    @media (max-width: 414px) {
        font-size: 40px;
    }
`;
