import React from "react";
import { Container, Image, LineRight, LineLeft } from "./styles/Footer";
import footer_logo from "../../images/secitadan_gelen/footer-main-logo.png";

export default function Footer({ ...restProps }) {
    return (
        <Container {...restProps}>
            <LineLeft />
            <Image src={footer_logo} />
            <LineRight />
        </Container>
    );
}
