import styled from "styled-components/macro";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
`;

export const Image = styled.img`
	display: flex;
	width: 25%;
	padding-bottom: 30px;

	@media (max-width: 1024px) {
		padding-top: 10px;
		width: 80%;
	}
	@media (max-width: 414px) {
		padding-top: 20px;
		width: 90%;
	}
`;

export const LineRight = styled.div`
	background-color: white;
	border-radius: 3px 0px 0px 3px;
	width: 40%;
	height: 5px;
`;

export const LineLeft = styled.div`
	background-color: white;
	border-radius: 0px 3px 3px 0px;
	width: 40%;
	height: 5px;
`;
