import React, { useState } from "react";
import { CoinCanvas, FaceRecog, Loading } from "../components";
import "./style.css";
import coin from "../images/para.svg";
import sac from "../images/kese.svg";
import logo from "../images/mainheaderlogo.png";
import mask from "../images/mask.svg";

export default function FaceRec() {
    const [smileState, setSmileState] = useState();
    const [hasStarted, setHasStarted] = useState();
    const [imageIsReady, setImageIsReady] = useState();

    const coinImg = new Image();
    const sacImage = new Image();
    const logoImage = new Image();
    const maskImage = new Image();

    coinImg.src = coin;
    sacImage.src = sac;
    logoImage.src = logo;
    maskImage.src = mask;

    logoImage.onload = () => {
        setImageIsReady(true);
    };

    return (
        <div className="camera-container">
            <FaceRecog
                setHasStarted={setHasStarted}
                setSmileState={setSmileState}
            />
            {hasStarted && imageIsReady ? (
                <CoinCanvas
                    smileState={smileState}
                    coinImg={coinImg}
                    sacImage={sacImage}
                    logoImage={logoImage}
                    maskImage={maskImage}
                    width="720"
                    height="560"
                />
            ) : (
                <Loading />
            )}
        </div>
    );
}
