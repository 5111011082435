import React from "react";
import { Footer, Form } from "../components";
import * as ROUTES from "../constants/routes";
import { useHistory } from "react-router-dom";

export default function Welcome({ name, setName, password, setPassword }) {
	const history = useHistory();

	const handleSubmit = (e) => {
		if (password === "santafarma") {
			e.preventDefault();
			history.push(ROUTES.KVKK);
		} else {
			alert("Hatalı Şifre!");
		}
	};

	return (
		<>
			<div className="center-div">
				<Form id="userForm" onSubmit={handleSubmit}>
					<Form.Container>
						<Form.Input
							placeholder="Ad ve Soyad..."
							required
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<Form.Input
							placeholder="Şifreniz..."
							required
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<Form.SubContainer>
							<Form.Button type="submit">Kaydet</Form.Button>
						</Form.SubContainer>
					</Form.Container>
				</Form>
			</div>
			<Footer />
		</>
	);
}
