import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`html, body, #root{
    margin: 0;
    height: 100%;
    min-height: 100%;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    background-color: rgb(0, 167, 224);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
`;
