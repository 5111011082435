import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled(motion.form)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 15vh;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40%;
	font-size: 16px;
	min-width: 220px;
	background-color: #eee;
	border-radius: 15px;
	padding: 20px;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.6);

	@media (max-width: 1024px) {
		width: 70%;
	}
`;

export const FormSubContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin: 1vh 2vw 0.2vh;
`;

export const Input = styled.input`
	width: 90%;
	font-family: "Teko", sans-serif;
	font-size: 40px;
	padding: 2vh 2vw 1vh;
	background-color: #eee;
	border-radius: 5px;
	border: solid 2px #ddd;
	margin: 5px;
	transition: 0.3s;

	&:focus {
		border: solid 2px #aac;
		outline: none;
		box-shadow: 0px 2px 10px rgba(14, 16, 62, 0.27);
	}

	@media (max-width: 1024px) {
		font-size: 32px;
	}
`;

export const RadioButton = styled.input.attrs({
	type: "checkbox",
	htmlFor: "remember",
	name: "remember",
	value: "Remember",
})`
	margin-right: 10px;
	min-width: 24px;
	min-height: 24px;
`;
export const RadioLabel = styled.label.attrs({
	htmlFor: "remember",
})``;

export const Button = styled.button`
	display: flex;
	justify-content: center;
	width: 100%;
	font-family: "Teko", sans-serif;
	min-height: 50px;
	min-width: 100px;
	font-size: 30px;
	padding: 2vh 2vw 1vh;
	color: #eee;
	background-color: rgb(0, 167, 224);
	border-radius: 5px;
	border: none;
	transition: 0.3s;

	&:hover {
		box-shadow: 0px 0px 0px 2px rgb(0, 167, 224);
	}
	&:focus {
		outline: none;
	}

	&:active {
		box-shadow: none;
		background-color: rgb(12, 147, 204);
	}

	@media (max-width: 1024px) {
		font-size: 32px;
	}
`;

export const DropDownButton = styled.button`
	display: flex;
	justify-content: center;
	width: 50%;
	font-family: "Teko", sans-serif;
	min-height: 50px;
	min-width: 100px;
	font-size: 30px;
	padding: 2vh 2vw 1vh;
	color: #eee;
	background-color: rgb(0, 167, 224);
	border-radius: 5px;
	border: none;
	margin-top: 10px;
	transition: 0.3s;

	&:hover {
		box-shadow: 0px 0px 0px 2px rgb(0, 167, 224);
	}
	&:focus {
		outline: none;
	}

	&:active {
		box-shadow: none;
		background-color: rgb(12, 147, 204);
	}
`;
