import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import "./styles/style.css";

export default function CoinCanvas({ smileState, coinImg, logoImage, maskImage, sacImage }) {
	const history = useHistory();

	const canvasRef = useRef(null);
	const contextRef = useRef(null);
	const timeRef = useRef(null);
	const logoRef = useRef(null);
	const maskRef = useRef(null);
	const coinRef = useRef(null);
	const sacRef = useRef(null);

	useEffect(() => {
		const animate = (time) => {
			if (!timeRef.current)
				timeRef.current = {
					start: time,
					smileDuration: 0,
					lastFrame: null,
				};
			const dt = (time - timeRef.current.lastFrame) / 1000.0;
			const elapsed = time - timeRef.current.start;

			const canvas = canvasRef.current;
			const context = contextRef.current;

			var coinInfo = coinRef.current;
			var sacInfo = sacRef.current;
			var logoInfo = logoRef.current;
			var maskInfo = maskRef.current;
			const speed = 3;

			const maskWidth = Math.max(((10 - timeRef.current.smileDuration) / 10) * (logoImage.width / 12), 0);

			context.clearRect(0, 0, canvas.width, canvas.height);

			context.save();
			context.translate(maskInfo.x + 280, 420);
			context.rotate((Math.PI / 180) * 180);
			// context.drawImage(
			//     maskImage,
			//     maskInfo.x,
			//     maskInfo.y,
			//     maskWidth,
			//     maskInfo.yHeight / 12
			// );
			context.restore();

			// context.globalCompositeOperation = "source-out";
			// context.drawImage(
			//     logoImage,
			//     logoInfo.x,
			//     logoInfo.y,
			//     logoImage.width / 12,
			//     logoImage.height / 12
			// );
			// context.globalCompositeOperation = "source-over";

			if (coinInfo.move) {
				if (dt < 4) timeRef.current.smileDuration += dt;

				if (coinInfo.x > sacInfo.x + 30) {
					coinInfo.x -= speed * dt * 17;
				} else if (coinInfo.y < 200) {
					coinInfo.y += speed * dt * 13;
					coinInfo.scale += 0.2 * dt * 15;
				} else if (coinInfo.y > 198) {
					coinInfo.x = 350;
					coinInfo.y = 120;
					coinInfo.alpha = 0;
					coinInfo.scale = 4;
				}

				if (coinInfo.alpha < 10) coinInfo.alpha += 0.2 * dt * 20;
			}

			context.save();
			context.globalAlpha = coinInfo.alpha / 10;

			context.drawImage(coinImg, coinInfo.x, coinInfo.y, coinImg.width / coinInfo.scale, coinImg.height / coinInfo.scale);
			context.restore();

			context.drawImage(sacImage, sacInfo.x, sacInfo.y, sacImage.width / 1.7, sacImage.height / 1.7);

			coinRef.current = coinInfo;
			sacRef.current = sacInfo;
			timeRef.current.lastFrame = time;

			if (timeRef.current.smileDuration > 10) {
				history.push(ROUTES.THANKYOU);
			} else {
				window.requestAnimationFrame(animate);
			}
		};

		const canvas = canvasRef.current;
		canvas.width = 720;
		canvas.height = 560;
		canvas.style.width = `${720}px`;
		canvas.style.height = `${560}px`;

		logoRef.current = {
			x: 70,
			y: 190,
		};

		maskRef.current = {
			x: 70,
			y: 190,
			xWidth: 2148,
			yHeight: 516,
		};
		coinRef.current = {
			move: false,
			x: 350,
			y: 140,
			alpha: 0,
			scale: 4,
		};

		sacRef.current = {
			collide: false,
			x: 280,
			y: 180,
		};

		const context = canvas.getContext("2d");
		context.scale(2, 2);
		contextRef.current = context;

		window.requestAnimationFrame(animate);
	}, []);

	useEffect(() => {
		coinRef.current.move = smileState;
	}, [smileState]);

	return <canvas ref={canvasRef} id="money-canvas"></canvas>;
}
