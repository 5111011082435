import React from "react";
import { Container, Image } from "./styles/HeaderImage";
import header_logo from "../../images/secitadan_gelen/main-header-logo.png";

export default function HeaderImage({ ...restProps }) {
    return (
        <Container {...restProps}>
            <Image src={header_logo} />
        </Container>
    );
}
