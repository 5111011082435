import React from "react";
import {
    Container,
    Input,
    RadioButton,
    RadioLabel,
    Button,
    DropDownButton,
    FormContainer,
    FormSubContainer,
} from "./styles/Form";

export default function Form({ children, ...restProps }) {
    return (
        <Container
            initial={{ opacity: 0, y: -25 }}
            animate={{ opacity: 1, y: 0 }}
            {...restProps}
        >
            {children}
        </Container>
    );
}

Form.Container = function FormFormContainer({ children, ...restProps }) {
    return <FormContainer {...restProps}>{children}</FormContainer>;
};

Form.SubContainer = function FormFormSubContainer({ children, ...restProps }) {
    return <FormSubContainer {...restProps}>{children}</FormSubContainer>;
};

Form.Input = function FormInput({ children, ...restProps }) {
    return <Input {...restProps}>{children}</Input>;
};

Form.RadioButton = function FormRadioButton({ children, ...restProps }) {
    return <RadioButton {...restProps}>{children}</RadioButton>;
};

Form.RadioLabel = function FormRadioLabel({ children, ...restProps }) {
    return <RadioLabel {...restProps}>{children}</RadioLabel>;
};

Form.Button = function FormButton({ children, ...restProps }) {
    return (
        <Button {...restProps}>
            {children}
        </Button>
    );
};

Form.DropDownButton = function FormDropDownButton({ children, ...restProps }) {
    return <DropDownButton {...restProps}>{children}</DropDownButton>;
};
