import React from "react";
import {
    Container,
    TextArea,
    RadioInput,
    Button,
    Label,
    SubContainer,
    ConfirmContainer,
} from "./styles/Kvkk";

export default function Kvkk({ children, ...restProps }) {
    return (
        <Container
            initial={{ opacity: 0, y: -25 }}
            animate={{ opacity: 1, y: 0 }}
            {...restProps}
        >
            {children}
        </Container>
    );
}

Kvkk.TextArea = function KvkkTextArea({ children, ...restProps }) {
    return (
        <TextArea readOnly spellCheck="false" resizable="no" {...restProps}>
            {children}
        </TextArea>
    );
};

Kvkk.SubContainer = function KvkkSubContainer({ children, ...restProps }) {
    return <SubContainer {...restProps}>{children}</SubContainer>;
};

Kvkk.RadioInput = function KvkkRadioInput({ children, ...restProps }) {
    return <RadioInput {...restProps}>{children}</RadioInput>;
};

Kvkk.Button = function KvkkButton({ children, ...restProps }) {
    return <Button {...restProps}>{children}</Button>;
};

Kvkk.Label = function KvkkLabel({ children, ...restProps }) {
    return <Label {...restProps}>{children}</Label>;
};

Kvkk.ConfirmContainer = function KvkkConfirmContainer({
    children,
    ...restProps
}) {
    return <ConfirmContainer {...restProps}>{children}</ConfirmContainer>;
};
