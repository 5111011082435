import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../constants/routes";

const apiSaveUsage = "https://api.santafarmaoes.prod.cmosteknoloji.com/api/home/saveUsage";

export default function FaceRec({ name, password }) {
    const history = useHistory();
    useEffect(() => {
        const data = new FormData();
        data.append("AppName", "Web Secita");
        data.append("Name", name);
        const settings = {
            method: "POST",
            mode: "cors",
            body: data,
        };
        fetch(apiSaveUsage, settings)
            .then((response) => response.text())
            .then((data) => {
                console.log(data);
            });
    }, []);

    return (
        <div onClick={() => history.push(ROUTES.WELCOME)} className="message-container">
            <p className="message">Gülümseyerek mutluluğunuzu bizimle paylaştığınız için teşekkür ederiz. </p>
            <p className="message">
                Bu gülümsemeniz karşılığında biz de sizin adınıza <em>İhtiyaç Haritası</em>
                'na bağışta bulunduk.
            </p>
        </div>
    );
}
