import React from "react";
import { Button, Container } from "./styles/Button";
import { useHistory } from "react-router-dom";

export default function ButtonContainer({
    width,
    value,
    routeOption,
    ...restProps
}) {
    const history = useHistory();

    return (
        <Container
            initial={{ opacity: 0, y: -25 }}
            animate={{ opacity: 1, y: 0 }}
            {...restProps}
        >
            <Button
                whileHover={{ scale: 1.02 }}
                onClick={() => history.push(routeOption)}
                width={width}
            >
                {value}
            </Button>
        </Container>
    );
}
