import React from "react";
import { Button, VerticalWrapper } from "../components";
import { Base } from "../container";
import { FaChevronUp } from "react-icons/fa";
import * as ROUTES from "../constants/routes";
import photoLogo from "../images/secita_parca/sayfa_4/camera.png";

export default function Choose() {
    return (
        <Base>
            <VerticalWrapper>
                <Button value="Gülümsemen bağış olsun" routeOption={ROUTES.FACEREC} width="50%" />
                <FaChevronUp color="white" size="3rem" />
                <img src={photoLogo} alt="camera-logo" />
            </VerticalWrapper>
        </Base>
    );
}
