import React from "react";
import { Button, Footer } from "../components";

import * as ROUTE from "../constants/routes";
import pills from "../images/ilac.svg";

export default function Welcome() {
    return (
        <>
            <div className="center-div">
                <Button value="Mutluluğu Paylaşın" routeOption={ROUTE.LOGIN} width="50%" />
                {/* <img id="pills" src={pills} alt="pills" /> */}
            </div>
            <Footer />
        </>
    );
}
