import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
    display: flex;
    position: absolute;
    z-index: 999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 167, 224);
    width: 100%;
    height: 100%;
`;

export const IconContainer = styled(motion.div)``;

export const Text = styled.p`
    font-size: 30px;
    color: white;
    font-family: "Teko", sans-serif;
    font-style: italic;
    text-shadow: 0px 0px 1px white, 0px 0px 1px white, 0px 0px 1px white;
`;
