import React, { useRef, useEffect } from "react";
import * as faceapi from "face-api.js";

export default function FaceRecog({ setSmileState, setHasStarted }) {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    function startVideo() {
        setHasStarted(true);
        navigator.mediaDevices
            .getUserMedia({ video: {} })
            .then((stream) => (videoRef.current.srcObject = stream))
            .then((err) => console.error(err));
        // navigator.getUserMedia(
        //     { video: {} },
        //     (stream) => (videoRef.current.srcObject = stream),
        //     (err) => console.error(err)
        // );
    }

    useEffect(() => {
        const video = videoRef.current;

        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
            faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
            faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
            faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]).then(startVideo);

        video.addEventListener("play", () => {
            const canvas = canvasRef.current;
            const displaySize = { width: video.width, height: video.height };
            faceapi.matchDimensions(canvas, displaySize);
            setInterval(async () => {
                const detections = await faceapi
                    .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                    .withFaceLandmarks()
                    .withFaceExpressions();
                const resizedDetections = faceapi.resizeResults(detections, displaySize);
                canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
                // faceapi.draw.drawDetections(canvas, resizedDetections);
                // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
                // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
                var happiness = false;
                for (const detection in detections) {
                    const expressions = detections[detection].expressions;
                    happiness = expressions.happy > 0.8;
                }

                setSmileState(happiness);
            }, 100);
        });
    }, []);

    return (
        <>
            <video width="720" id="video" height="560" autoPlay muted ref={videoRef} />
            <canvas className="detection-canvas" width="720" height="560" ref={canvasRef} />
        </>
    );
}
