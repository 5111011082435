import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    min-width: 220px;
    background-color: #eee;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.6);
`;

export const TextArea = styled.textarea`
    width: 95%;
    height: 25vh;
    min-height: 90px;
    padding: 2vh 2vw 2vh;
    border: none;
    border-radius: 5px;
    resize: none;
    outline: none;
    line-height: 1.5em;
    font-weight: 300;
    color: transparent;
    text-shadow: 0 0 1px #000000, 0 0 1px #000000;
    font-family: "Oswald", sans-serif;
    font-size: 16px;
    padding: 20px;
    box-shadow: 0px 5px 8px 1px rgba(30, 30, 30, 0.2);
    background: rgb(184, 184, 184);
    border-width: 3px;
    border-style: solid;
    border-color: rgba(255, 255, 255);

    &:focus {
        outline: none;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const RadioInput = styled.input`
    font-size: 22px;
    margin-left: 1vw;
    transform: scale(1.5);
`;

export const Button = styled(motion.button)`
    border: none;
    border-radius: 5px;
    font-size: 32px;
    width: 100%;
    padding: 2vh 3vw 1vh;
    color: ${({ isDisabled }) => (isDisabled ? "#666" : "white")};
    font-family: "Teko", sans-serif;
    font-style: italic;
    background-color: ${({ isDisabled }) =>
        isDisabled ? "rgb(52, 57, 64)" : "rgb(0, 167, 224)"};

    transition: 0.3s;

    pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "initial")};

    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: ${({ isDisabled }) =>
            isDisabled ? "none" : "0px 0px 0px 2px rgb(0, 167, 224)"};
    }
`;

export const Label = styled.p`
    margin-right: auto;
    font-size: 22px;
    color: rgb(0, 167, 224);
    font-family: "Oswald", sans-serif;
    margin-left: 1vw;
    text-shadow: 0px 0px 1px rgb(0, 167, 224), 0px 0px 1px rgb(0, 167, 224);
`;

export const ConfirmContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
`;
