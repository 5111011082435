import React from "react";
import { HeaderImage, Footer } from "../../components";
import { Container } from "./styles/Base";

export default function Base({ children, ...restProps }) {
    return (
        <Container {...restProps}>
            <HeaderImage />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                {children}
            </div>
            <Footer />
        </Container>
    );
}
